import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

function SolutionContents() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const solutions = [
    {
      imageSrc: "/images/solution1.png",
      title: t("blockchainSolution"),
      subtitle: t("blockchainSolutionSubtitle"),
      link: "/blockChain",
      isExternal: false,
    },
    {
      imageSrc: "/images/solution2.png",
      title: t("akamaiCloud"),
      subtitle: t("akamaiCloudSubtitle"),
      link: "/akamai",
      isExternal: false,
    },
    {
      imageSrc: "/images/solution3.png",
      title: t("walletVerificationSolution"),
      subtitle: t("walletVerificationSolutionSubtitle"),
      link: "http://secu.retrust.world",
      isExternal: true,
    },
  ];

  const handleNavigation = (link, isExternal) => {
    if (isExternal) {
      // 외부 링크는 그대로 새 탭으로
      window.open(link, "_blank", "noopener,noreferrer");
    } else if (link === "/akamai") {
      // akamai 페이지는 새 탭으로
      window.open(link, "_blank", "noopener,noreferrer");
    } else {
      // 나머지는 일반 네비게이션
      navigate(link);
    }
  };

  const swiperParams = {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    modules: [Pagination, Autoplay],
    className: "w-full",
  };

  return (
    <div className="w-full px-3">
      {isMobile ? (
        <Swiper {...swiperParams}>
          {solutions.map((solution, index) => (
            <SwiperSlide key={index}>
              <ServiceCard {...solution} onNavigate={handleNavigation} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="flex flex-col md:flex-row md:flex-wrap md:gap-5">
          {solutions.map((solution, index) => (
            <div key={index} className="w-full md:w-[calc(50%-10px)] mb-4">
              <ServiceCard {...solution} onNavigate={handleNavigation} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SolutionContents;
