import React, { useState, useEffect } from "react";
import ServiceCard from "./ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

function SkillContents() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const skills = [
    {
      imageSrc: "/images/skill1.png",
      title: t("nodeValidating"),
      subtitle: t("nodeValidatingSubtitle"),
      description: t("nodeValidatingDescription"),
    },
    {
      imageSrc: "/images/skill2.png",
      title: t("blockchainConsulting"),
      subtitle: t("blockchainConsultingSubtitle"),
      description: t("blockchainConsultingDescription"),
    },
  ];

  const swiperParams = {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    modules: [Pagination, Autoplay],
    className: "w-full",
  };

  return (
    <div className="w-full px-3">
      {isMobile ? (
        <Swiper {...swiperParams}>
          {skills.map((skill, index) => (
            <SwiperSlide key={index}>
              <ServiceCard {...skill} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="flex flex-col md:flex-row md:flex-wrap md:gap-5">
          {skills.map((skill, index) => (
            <div key={index} className="w-full md:w-[calc(50%-10px)] mb-4">
              <ServiceCard {...skill} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SkillContents;
