import React, { useState } from "react";
import OpenGuardNFT from "./OpenGuardNFT";
import Mainnet from "./Mainnet";
import SmartIdentity from "./SmartIdentity";
import Certificate from "./Certificate";
import { useTranslation } from "react-i18next";

const tabContents = {
  OpenGuardNFT: () => <OpenGuardNFT />,
  EnterprisePrivateMainnet: () => <Mainnet />,
  SmartIndentifyProof: () => <SmartIdentity />,
  FixedDateCertificate: () => <Certificate />,
};

const tabColors = {
  OpenGuardNFT: "bg-[#27ae60] hover:bg-[#229954]",
  EnterprisePrivateMainnet: "bg-[#27ae60] hover:bg-[#229954]",
  SmartIndentifyProof: "bg-[#27ae60] hover:bg-[#229954]",
  FixedDateCertificate: "bg-[#27ae60] hover:bg-[#229954]",
};

function BlockChainFilter() {
  const [activeTab, setActiveTab] = useState("OpenGuardNFT");
  const { t } = useTranslation();

  return (
    <div className="w-full min-h-screen bg-[#dbe2db] text-gray-800 px-4 py-32">
      <div className="max-w-7xl mx-auto">
        <h3 className="md:mb-20 mb-5 text-xl md:text-3xl font-semibold">
          {t("retrustSolution")}
        </h3>
        <div className="flex flex-col md:flex-row gap-4">
          <nav className="md:w-1/4 space-y-2">
            {Object.keys(tabContents).map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`w-full p-4 text-left rounded-lg font-medium transition-all duration-300 ease-in-out
                  ${
                    activeTab === tab
                      ? `${
                          tabColors[tab].split(" ")[0]
                        } text-white shadow-lg transform scale-105`
                      : "bg-white text-gray-700 hover:bg-[#e8f5e9]"
                  }`}
              >
                {tab === "OpenGuardNFT"
                  ? "OpenGuardNFT"
                  : tab === "EnterprisePrivateMainnet"
                  ? "Enterprise Private Mainnet"
                  : tab.replace(/([A-Z])/g, " $1").trim()}
              </button>
            ))}
          </nav>
          <div className="bg-white rounded-lg p-6 md:w-3/4 shadow-lg">
            <div>{tabContents[activeTab]()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockChainFilter;
