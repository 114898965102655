import React from "react";
import { useTranslation } from "react-i18next";

function OpenGuardNFT() {
  const { t } = useTranslation();
  return (
    <div className="w-full p-6 sm:p-8 bg-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-light text-[#27ae60] mb-6">
          OpenGuardNFT
        </h2>
        <p className="text-sm md:text-lg text-gray-600 mb-10 sm:mb-14 leading-relaxed max-w-3xl">
          {t("NFT_title")}
        </p>

        <div className="space-y-16 sm:space-y-20">
          <div className="flex flex-col md:flex-row items-center md:items-stretch">
            <div className="w-full md:w-2/5 mb-6 md:mb-0 md:mr-10 flex items-center justify-center">
              <img
                src="/images/NFT_images1.png"
                alt="NFT Authentication"
                className="object-contain max-h-56 md:max-h-full"
              />
            </div>
            <div className=" md:w-3/5 flex flex-col justify-center">
              <p className="text-base md:text-3xl font-normal text-[#27ae60] mb-3">
                {t("NFT_Authentication")}
              </p>
              <p className="text-sm md:text-lg text-gray-500">
                {t("NFT_Authentication_desc")}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row-reverse items-center md:items-stretch">
            <div className="w-full md:w-2/5 mb-6 md:mb-0 md:ml-10 flex items-center justify-center">
              <img
                src="/images/NFT_images2.png"
                alt="Private Information Management"
                className="object-contain max-h-56 md:max-h-full"
              />
            </div>
            <div className="md:w-3/5 flex flex-col justify-center">
              <p className="text-base md:text-3xl font-normal text-[#27ae60] mb-3">
                {t("NFT_privateTitle1")} <br />
                {t("NFT_privateTitle2")}
              </p>
              <p className="text-sm md:text-lg text-gray-500">
                {t("NFT_privateTitle_desc")}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center md:items-stretch">
            <div className="w-full md:w-2/5 mb-6 md:mb-0 md:mr-10 flex items-center justify-center">
              <img
                src="/images/NFT_delivery.png"
                alt="Membership Delivery"
                className="object-contain max-h-56 md:max-h-full"
              />
            </div>
            <div className="md:w-3/5 flex flex-col justify-center">
              <h3 className="text-lg md:text-3xl font-normal text-[#2ecc71] mb-3">
                {t("MemberShip_title")}
              </h3>
              <p className="text-sm md:text-lg text-gray-600 mb-3">
                {t("MemberShip_desc")}
              </p>
              <p className="text-sm md:text-lg text-gray-500">
                {t("MemberShip_subDesc")}
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row-reverse items-center md:items-stretch">
            <div className="w-full md:w-2/5 mb-6 md:mb-0 md:ml-10 flex items-center justify-center">
              <img
                src="/images/NFT_Members.png"
                alt="Membership Benefits"
                className="object-contain max-h-56 md:max-h-full"
              />
            </div>
            <div className="md:w-3/5 flex flex-col justify-center">
              <h3 className="text-lg md:text-3xl font-normal text-[#2ecc71] mb-3">
                {t("Membership_benefits_title")}
              </h3>
              <p className="text-sm md:text-lg text-gray-600 mb-3">
                {t("Membership_benefits_desc")}
              </p>
              <p className="text-sm md:text-lg text-gray-500">
                {t("Membership_benefits_subDesc")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenGuardNFT;
