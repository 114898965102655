import React from "react";
import { useTranslation } from "react-i18next";

function SmartIdentity() {
  const { t } = useTranslation();
  return (
    <div className="w-full py-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-light text-[#27ae60] mb-6">
            Smart Identity Proof
          </h2>
          <p className="text-sm md:text-lg text-gray-600 max-w-4xl mx-auto">
            {t("SmartIdentity_Title")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <FeatureCard
            title="Identity Proof"
            description={t("ProofContents_desc")}
            imageSrc="/images/identity_proof.png"
            applyBackground={false}
            largeImage={true}
          />
          <FeatureCard
            title={t("FeatureCard2_title")}
            description={t("FeatureCard2_desc")}
            imageSrc="/images/blockChainNetwork.png"
            applyBackground={false}
            largeImage={true}
          />
          <FeatureCard
            title={t("FeatureCard3_title")}
            imageSrc="/images/prevent.png"
            applyBackground={true}
            largeImage={false}
          />
          <FeatureCard
            title={t("FeatureCard4_title")}
            imageSrc="/images/contractService.png"
            applyBackground={true}
            largeImage={false}
          />
        </div>
      </div>
    </div>
  );
}

function FeatureCard({
  title,
  description,
  imageSrc,
  applyBackground,
  largeImage,
}) {
  return (
    <div className="bg-gray-50 p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col">
      <div className="flex-grow">
        <h3 className="text-xl md:text-2xl font-normal text-[#27ae60] mb-4">
          {title}
        </h3>
        {description && (
          <p className="text-sm md:text-base text-gray-600 mb-6">
            {description}
          </p>
        )}
      </div>
      <div
        className={`rounded-lg flex items-center justify-center mt-4 ${
          largeImage ? "h-48 md:h-56" : "h-32 md:h-40"
        } ${applyBackground ? "bg-[#529e56]" : ""}`}
      >
        <img
          src={imageSrc}
          alt={title}
          className={`object-contain ${
            largeImage ? "max-h-full w-auto" : "max-h-24 md:max-h-32 w-auto"
          }`}
        />
      </div>
    </div>
  );
}

export default SmartIdentity;
