import React from "react";
import { useTranslation } from "react-i18next";

function AkamaiPlan() {
  const { t } = useTranslation();

  const planDataArray = [
    {
      title: t("dedicatedCPU"),
      desc: t("dedicatedCPUDesc"),
      price: "$36",
      date: t("perMonth"),
    },
    {
      title: t("sharedCPU"),
      desc: t("sharedCPUDesc"),
      price: "$5",
      date: t("perMonth"),
    },
    {
      title: t("highMemory"),
      desc: t("highMemoryDesc"),
      price: "$60",
      date: t("perMonth"),
    },
    {
      title: t("gpuRTX6000"),
      desc: t("gpuRTX6000Desc"),
      price: "$1000",
      date: t("perMonth"),
    },
    {
      title: t("premium2CPU"),
      desc: t("premium2CPUDesc"),
      price: "$43",
      date: t("perMonth"),
    },
  ];

  return (
    <div
      className="w-full bg-gradient-to-br from-green-50 to-yellow-50 py-8 md:pt-36 md:pb-20"
      id="plan"
    >
      <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full mb-8 md:mb-16">
          <h3 className="text-2xl md:text-3xl font-bold text-[#1b2b3e] mb-2">
            {t("akamaiLinodePlanGuide")}
          </h3>
          <p className="text-sm md:text-lg text-[#1b2b3e]">
            {t("cloudServiceConsultation")}
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {planDataArray.map((plan, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 border-t-4 border-[#a7dd7b]"
            >
              <div className="p-6">
                <h4 className="text-lg font-semibold mb-5 text-green-600">
                  {plan.title}
                </h4>
                <p className="text-gray-600 mb-4 text-sm sm:text-base line-clamp-3 sm:line-clamp-none">
                  {plan.desc}
                </p>
                <div className="flex items-end justify-between">
                  <div>
                    <span className="text-2xl sm:text-3xl font-bold text-[#386937]">
                      {plan.price}
                    </span>
                    <span className="text-gray-500 ml-1 text-sm sm:text-base">
                      {plan.date}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 sm:mt-16 md:mt-20">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe2lAgtkwamELsExZZrM2vg7XeOhmcF3REWjxw5Wzlxpx1pTA/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto block bg-[#386937] hover:bg-[#2d5429] text-white font-bold py-4 sm:py-5 md:py-6 px-6 rounded-xl text-lg sm:text-xl md:text-2xl transition-colors duration-300 text-center"
          >
            {t("getConsultationAndUse")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default AkamaiPlan;
