import React from "react";
import email from "../assets/email.svg";
import linkedin from "../assets/linkedin.svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="w-full px-4 py-8 border-t" id="contact">
      <div className="max-w-[1280px] w-[96%] mx-auto flex flex-col items-center lg:items-start text-center lg:text-left">
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-8 w-full">
          <div className="flex flex-col gap-6 w-full lg:w-2/3">
            <h1 className="text-sm mmb:text-base md:text-lg">
              {t("footerDescription")}
            </h1>
            <div className="flex flex-col mmb:flex-row lg:flex-col items-center lg:items-start justify-center lg:justify-start gap-4">
              <p className="text-xs mmb:text-lg md:text-base font-semibold">
                {t("inquiry")}: biz@retrust.world
              </p>
            </div>
          </div>
          <div className="flex gap-4 items-center lg:self-start">
            <a
              href="mailto:biz@retrust.world"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={email}
                alt={t("email")}
                className="w-8 h-8 mmb:w-10 mmb:h-10 md:w-10 md:h-10 cursor-pointer"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/retrust"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedin}
                alt={t("linkedin")}
                className="w-8 h-8 mmb:w-10 mmb:h-10 md:w-10 md:h-10 cursor-pointer"
              />
            </a>
          </div>
        </div>
        <div className="mt-8 text-xs mmb:text-sm">
          <div className="flex flex-col mmb:flex-row items-center justify-center lg:justify-start gap-1">
            <p>{t("companyInfo")}</p>
            <span className="hidden mmb:inline text-xs text-[#2d2c2c]">|</span>
            <p>{t("businessNumber")}</p>
          </div>
          <div className="flex flex-col  gap-2 py-2">
            <span className="hidden">{t("addressTitle")}</span>
            <div className="flex  gap-1 flex-col">
              <p> {t("address")}</p>
              <p> {t("address2")}</p>
            </div>
          </div>
          <p>통신판매업신고번호 제 2024-부산남구-0464 호 추가</p>
          <p className="mt-4">{t("copyright")}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
