import video from "../video/viedo1.mp4";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Banner() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClick = () => {
    window.location.href = "https://insu.retrust.world/";
  };

  return (
    <div
      className="relative w-full h-screen overflow-hidden md:mt-20"
      id="intro"
    >
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="absolute inset-0 bg-gradient-to-r from-black via-black/50 to-transparent">
        <div className="relative z-10 h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-14 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -50 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-white space-y-4 tracking-tight sm:space-y-6"
            >
              <h1 className="text-2xl md:text-4xl lg:text-4xl font-bold ">
                {t("bannerTitle")}
              </h1>

              <ul className="space-y-2 text-gray-300 text-sm sm:text-base">
                <li className="flex items-center">
                  <svg
                    className="w-4 h-4 sm:w-6 sm:h-6 mr-2 text-green-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  <span>{t("bannerPoint1")}</span>
                </li>
                {/* Add more list items as needed */}
              </ul>
              <div className="flex justify-center lg:justify-start pt-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 sm:py-3 sm:px-8 rounded-full text-sm sm:text-base lg:text-lg transition duration-300 ease-in-out transform hover:shadow-lg"
                  onClick={handleClick}
                >
                  {t("button")}
                </motion.button>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="relative mt-8 lg:mt-0"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-green-100 to-[#9ee29e] rounded-lg transform rotate-6"></div>
              <img
                src="/images/wideBanner.png"
                alt="Banner"
                className="relative z-10 rounded-lg shadow-2xl cursor-pointer w-full h-auto"
                onClick={handleClick}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
