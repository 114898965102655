import React from "react";
import { useTranslation } from "react-i18next";

function BlockChainBanner() {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-20 relative">
        <div className="h-[620px] relative">
          <img
            src="/images/blockChainBanner.png"
            alt="solutionImage"
            className="h-full w-full object-cover filter brightness-75"
          />
          {/* Semi-transparent overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          {/* Content over the image */}
          <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-4">
            <h3 className="md:text-3xl font-bold mb-4 text-xl">
              {t(
                "blockchainSolutionTitle",
                "Introduction to ReTrust Solutions"
              )}
            </h3>
            <p>
              {t(
                "blockchainSolutionSubtitle",
                "Introduce blockchain solutions."
              )}
            </p>
            <p>
              {t(
                "blockchainSolutionDescription",
                "Easy to apply and provide more benefits to your customers."
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockChainBanner;
