import React from "react";
import { useTranslation } from "react-i18next";

function Mainnet() {
  const { t } = useTranslation();
  return (
    <div className="w-full p-6 sm:p-8 bg-white">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-light text-[#27ae60] mb-6">
            Enterprise Private Mainnet
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-3xl mx-auto">
            {t("Mainnet_Title")}
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <FeatureBlock title={t("Mainnet_First_MenuTitle")}>
            <li>{t("Mainnet_First_item1")}</li>
            <li>{t("Mainnet_First_item2")}</li>
            <li>{t("Mainnet_First_item3")}</li>
          </FeatureBlock>

          <div className="hidden md:block w-px h-48 bg-gray-300"></div>

          <div className="w-full md:w-1/3 flex justify-center">
            <img
              src="/images/blockImage.png"
              alt="Blockchain Illustration"
              className="w-4/5 md:w-full max-w-sm object-contain"
            />
          </div>

          <div className="hidden md:block w-px h-48 bg-gray-300"></div>

          <FeatureBlock title={t("Mainnet_Second_MenuTitle")}>
            <li>{t("Mainnet_Second_item1")}</li>
            <li>{t("Mainnet_Second_item2")}</li>
            <li>{t("Mainnet_Second_item3")}</li>
          </FeatureBlock>
        </div>
      </div>
    </div>
  );
}

function FeatureBlock({ title, children }) {
  return (
    <div className="w-full md:w-1/4 flex flex-col items-center md:items-start text-center md:text-left">
      <h3 className="text-xl md:text-2xl font-medium text-[#27ae60] mb-5">
        {title}
      </h3>
      <ul className="list-disc list-inside text-base md:text-lg text-gray-700 space-y-3">
        {children}
      </ul>
    </div>
  );
}

export default Mainnet;
