import React from "react";
import { useTranslation } from "react-i18next";

function AkamaiBanner() {
  const { t } = useTranslation();

  const formUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSe2lAgtkwamELsExZZrM2vg7XeOhmcF3REWjxw5Wzlxpx1pTA/viewform";

  return (
    <>
      <div className="mt-20 relative">
        <div className="h-[620px] relative">
          <img
            src="/images/akamaiBanner.png"
            alt={t("akamaiBannerAlt")}
            className="h-full w-full object-cover filter brightness-75"
          />
          {/* Semi-transparent overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          {/* Content over the image */}
          <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white p-4">
            <h3 className="md:text-3xl font-bold mb-4 text-xl">
              {t("freeCloudUsage")}
            </h3>
            <p className="mb-14">
              {t("akamaiCloudIntro1")}
              <br /> {t("akamaiCloudIntro2")}
            </p>
            <a
              href={formUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-[#386937] hover:bg-[#2d5429] px-16 py-4 rounded md:text-xl transition-colors duration-300"
            >
              {t("useFree")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AkamaiBanner;
