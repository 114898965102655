import React, { useState, useEffect, useRef } from "react";
import rightArrow from "../assets/rightArrow.svg";
import leftArrow from "../assets/leftArrow.svg";
import { useTranslation } from "react-i18next";

function News() {
  const { t, i18n } = useTranslation(); // i18n 추가
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      content: t("news8Content"),
      date: "2024-10-31",
      imageUrl: "/images/news_09.png",
      link: "https://www.blockchaintoday.co.kr/news/articleView.html?idxno=47664",
    },
    {
      content: t("news7Content"),
      date: "2024-10-11",
      imageUrl: "/images/news_08.png",
      link: "https://www.etnews.com/20241011000264",
    },
    {
      content: t("news6Content"),
      date: "2024-09-23",
      imageUrl: "/images/news_07.png",
      link: "https://www.blockchaintoday.co.kr/news/articleView.html?idxno=46819",
    },
    {
      content: t("news5Content"),
      date: "2024-08-01",
      imageUrl: "/images/news_nest.png",
      link: "https://news.mt.co.kr/mtview.php?no=2024073121293199348",
    },
    {
      content: t("news4Content"),
      date: "2024-07-02",
      imageUrl: "/images/news_meritz.png",
      link: "https://n.news.naver.com/article/030/0003219725?sid=101",
    },
    {
      content: t("news3Content"),
      date: "2024-3-14",
      imageUrl: "/images/news_cluster.png",
      link: "https://m.etnews.com/20240314000172",
    },
    {
      content: t("news2Content"),
      date: "2023-11-24",
      imageUrl: "/images/web3in_busan.png",
      link: "https://m.etnews.com/20231127000256",
    },
    {
      content: t("news1Content"),
      date: "2023-11-01",
      imageUrl: "/images/openGuardNFT_RT.png",
      link: "https://m.etnews.com/20231127000256",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsLargeScreen(width >= 1024);
      setIsMobile(width < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, slides.length - (isLargeScreen ? 2 : 1))
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const showPrevButton = currentIndex > 0;
  const showNextButton = isLargeScreen
    ? currentIndex < slides.length - 2
    : currentIndex < slides.length - 1;

  const handleDragStart = (e) => {
    setIsDragging(true);
    setStartX(isMobile ? e.touches[0].clientX : e.clientX);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = isMobile ? e.touches[0].clientX : e.clientX;
    const diff = startX - x;

    if (Math.abs(diff) > 5) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setIsDragging(false);
    }
  };

  return (
    <section
      className="w-full px-4 py-20 md:pt-40 md:pb-32 bg-[#dbe2db] overflow-hidden"
      id="news"
    >
      <div className="max-w-[1280px] mx-auto w-[96%]">
        <div className="flex justify-between items-center mb-6 md:mb-8">
          <div className="flex gap-1">
            <h3 className="text-lg md:text-2xl font-semibold">
              {t("retrustNews")}
            </h3>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handlePrev}
              className={`bg-[#353535] text-white p-2 rounded-full transition-opacity duration-300 ${
                showPrevButton
                  ? "opacity-70 hover:opacity-100"
                  : "opacity-0 cursor-default"
              }`}
              disabled={!showPrevButton}
            >
              <img src={leftArrow} alt="prev" className="w-4 h-4" />
            </button>
            <button
              onClick={handleNext}
              className={`bg-[#353535] text-white p-2 rounded-full transition-opacity duration-300 ${
                showNextButton
                  ? "opacity-70 hover:opacity-100"
                  : "opacity-0 cursor-default"
              }`}
              disabled={!showNextButton}
            >
              <img src={rightArrow} alt="next" className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div
          className="relative overflow-visible"
          ref={sliderRef}
          onMouseDown={isMobile ? null : handleDragStart}
          onMouseUp={isMobile ? null : handleDragEnd}
          onMouseLeave={isMobile ? null : handleDragEnd}
          onMouseMove={isMobile ? null : handleDragMove}
          onTouchStart={isMobile ? handleDragStart : null}
          onTouchEnd={isMobile ? handleDragEnd : null}
          onTouchMove={isMobile ? handleDragMove : null}
        >
          <div
            className="flex transition-transform duration-300 ease-out"
            style={{
              transform: `translateX(-${
                currentIndex * (isLargeScreen ? 40 : 85)
              }%)`,
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`flex-shrink-0 px-[6px] md:px-2 ${
                  isLargeScreen ? "w-[40%]" : "w-[85%]"
                }`}
              >
                <a
                  href={slide.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block md:h-[500px] h-[400px]"
                >
                  <div className="md:p-5 pt-10 h-full bg-white shadow-lg rounded-lg text-center pb-7 transition-transform duration-300 hover:scale-105">
                    <div className="flex flex-col justify-around gap-2 items-center h-full">
                      <p
                        className={` font-semibold px-2 ${
                          i18n.language === "en"
                            ? "basis-[20%] text-[1rem]  md:text-lg"
                            : "basis-[0%] text-lg"
                        }`}
                      >
                        {slide.content}
                      </p>
                      <p className="text-xs md:text-sm text-black mb-2">
                        {slide.date}
                      </p>
                      <div className="w-full h-48 md:h-[350px] overflow-hidden px-6">
                        <img
                          src={slide.imageUrl}
                          alt=""
                          className="w-full h-full object-cover rounded-3xl"
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
