import React, { useState, useEffect } from "react";
import upArrow from "../assets/upArrow.svg";

function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);

  // 스크롤 이벤트 핸들러
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // 스크롤을 상단으로 이동시키는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="fixed cursor-pointer bottom-5 right-5 w-12 h-12 bg-[#386937] rounded-full flex items-center justify-center shadow-lg opacity-70 transition duration-300 z-[9999]"
        >
          <img
            src={upArrow}
            alt="Scroll to top"
            className="w-6 h-6 text-white" // Tailwind CSS 클래스를 사용하여 크기 조정
          />
        </div>
      )}
    </div>
  );
}

export default ScrollTop;
