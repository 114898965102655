import React from "react";
import { useTranslation } from "react-i18next";

function ServiceCard({
  imageSrc,
  title,
  subtitle,
  description,
  link,
  isExternal,
  onNavigate,
}) {
  const { t } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    onNavigate(link, isExternal);
  };

  return (
    <div className="pt-2">
      <div className="w-full bg-white shadow-lg rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div className="flex flex-col mmb:flex-row md:flex-col lg:flex-row">
          <div className="w-full mmb:w-1/3 md:w-[100%] lg:w-[40%] aspect-video mmb:aspect-square md:aspect-video lg:aspect-square overflow-hidden">
            <img
              src={imageSrc}
              alt={title}
              className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="flex flex-col justify-between p-4 mmb:w-2/3 md:w-full lg:w-3/5 py-4">
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
              <p className="text-sm text-gray-600 mb-2 mb:block">{subtitle}</p>
              <p className="text-sm text-gray-700 line-clamp-3 mmb:line-clamp-2 md:line-clamp-none hidden md:block">
                {description}
              </p>
            </div>
            {link && (
              <div className="mt-4 flex justify-end">
                <button
                  onClick={handleClick}
                  className="bg-[#a7dd7b] text-white rounded-full text-sm md:text-base px-3 py-[6px] hover:bg-[#516640] transition-colors duration-200"
                >
                  {t("learnMore")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
