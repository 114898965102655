import React from "react";
import { useTranslation } from "react-i18next";

function Mission() {
  const { t } = useTranslation();

  return (
    <section id="mission" className="w-full px-4 pt-24 md:pt-32">
      <div className="max-w-[1280px] mx-auto w-[96%]">
        <div className="flex flex-wrap mb-2 ">
          <h2 className="text-xl md:text-2xl font-semibold">
            {t("ourMission")}
          </h2>
          <p className="text-xl md:text-2xl font-semibold text-[#386937]">
            {t("connectingMoreWorlds")}
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-stretch gap-8 md:gap-12 mt-6">
          {[
            {
              img: "First",
              title: "beyondReality",
              desc: "connectingRealWorldWeb3",
            },
            {
              img: "Snd",
              title: "redefiningLimits",
              desc: "utilizingDecentralization",
            },
            {
              img: "Thd",
              title: "creatingFuture",
              desc: "forSustainableFuture",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex flex-col w-[98%] mx-auto md:w-full bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <div className="relative h-48 md:h-64">
                <img
                  src={`/images/missionImage${item.img}.jpg`}
                  alt={`mission${index + 1}`}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30"></div>
              </div>
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-2">{t(item.title)}</h3>
                <p className="text-sm text-gray-600">{t(item.desc)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Mission;
