import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";

function ServiceContents() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const services = [
    {
      imageSrc: "/images/Fa_retrust.png",
      title: "FaRE Trust",
      subtitle: t("fareTrustSubtitle"),
      description: t("fareTrustDescription"),
      link: "https://fa.retrust.world",
      isExternal: true,
    },
    {
      imageSrc: "/images/insuTrustPage.png",
      title: "InsuRE Trust",
      subtitle: t("insuRETrustSubtitle"),
      description: t("insuRETrustDescription"),
      link: "https://insu.retrust.world/",
      isExternal: true,
    },
    // 추가 서비스를 여기에 넣을 수 있습니다.
  ];

  const handleNavigation = (link, isExternal) => {
    if (isExternal) {
      window.open(link, "_blank", "noopener,noreferrer");
    } else {
      navigate(link);
    }
  };

  const swiperParams = {
    effect: "cards",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    modules: [EffectCards, Pagination],
    className: "mySwiper",
  };

  return (
    <div className="w-full px-3">
      {isMobile ? (
        <Swiper {...swiperParams}>
          {services.map((service, index) => (
            <SwiperSlide key={index}>
              <div className="p-2">
                <ServiceCard {...service} onNavigate={handleNavigation} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {services.map((service, index) => (
            <div key={index} className="w-full">
              <ServiceCard {...service} onNavigate={handleNavigation} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ServiceContents;
