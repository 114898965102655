import React from "react";
import { useTranslation } from "react-i18next";

function AkamaiMerit() {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full">
        <div
          className="max-w-[1280px] mx-auto md:pt-36 md:pb-20 pt-20 pb-12 px-4"
          id="merit"
        >
          <h3 className="text-lg md:text-3xl font-bold mb-8">
            {t("differentiation")}
          </h3>
          <div className="flex flex-col gap-8 md:gap-12">
            <div className="rounded-lg shadow-lg overflow-hidden">
              <img
                src="/images/akamai_2.png"
                alt={t("akamaiGraphAlt")}
                className="w-full h-auto object-contain"
              />
            </div>
            <div className="rounded-lg shadow-lg overflow-hidden">
              <img
                src="/images/akamai_1.png"
                alt={t("akamaiGraphAlt")}
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AkamaiMerit;
