import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Mmenu from "../assets/mobileMenu.svg";
import close from "../assets/close.svg";
import { useMenuToggle } from "../hook/useMenuToggle";
import { useLanguageChange } from "../hook/useLanguageChange";

function AkamaiNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMenuOpen, toggleMenu, closeMenu } = useMenuToggle();
  const changeLanguage = useLanguageChange();

  const homeBtn = () => {
    navigate("/");
  };

  const menuItems = [
    { name: t("blockChain"), link: "/blockChain" },
    { name: t("newStandard"), id: "select" },
    { name: t("uniqueness"), id: "merit" },
    { name: t("planInfo"), id: "plan" },
  ];

  const smoothScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleNavClick = (item) => {
    closeMenu();
    if (item.link) {
      navigate(item.link);
    } else if (item.id) {
      smoothScrollTo(item.id);
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 w-full bg-white shadow-md z-50">
      <div className="max-w-[1280px] mx-auto py-4 px-4 md:px-0 md:py-6">
        <div className="flex flex-col">
          <div className="hidden md:flex md:justify-end md:items-center mr-4">
            <button
              className="text-xs hover:text-green-700 transition-colors"
              onClick={() => changeLanguage("ko")}
            >
              {t("ko")}
            </button>
            <span className="text-xs px-2">|</span>
            <button
              className="text-xs hover:text-green-700 transition-colors"
              onClick={() => changeLanguage("en")}
            >
              {t("en")}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div
              className="w-[140px] h-[40px] sm:w-[150px] sm:h-[50px] cursor-pointer"
              onClick={homeBtn}
            >
              <img
                src="/images/RetrustLogo.png"
                alt="logo"
                className="object-cover w-full h-full"
              />
            </div>

            <div className="flex items-center">
              <div className="md:hidden">
                <button onClick={toggleMenu} className="focus:outline-none">
                  <img src={Mmenu} alt="mobile menu" className="w-6 h-6" />
                </button>
              </div>
            </div>

            <nav
              className={`
                fixed top-0 right-0 h-full bg-white w-[80%] shadow-lg z-[200]
                transform transition-transform duration-300 ease-in-out
                ${isMenuOpen ? "translate-x-0" : "translate-x-full"}
                md:relative md:transform-none md:shadow-none md:w-auto md:bg-transparent md:z-auto
              `}
            >
              <div className="h-full flex flex-col pt-10 px-8 md:p-0">
                <div className="flex justify-end w-full mb-10 md:hidden">
                  <button onClick={closeMenu} className="focus:outline-none">
                    <img src={close} alt="Close menu" width={20} />
                  </button>
                </div>
                <ul className="flex flex-col gap-8 md:flex-row md:items-center md:p-0 flex-grow">
                  {menuItems.map((item, index) => (
                    <li key={index} className="md:mx-3">
                      <a
                        href={item.link ? item.link : `#${item.id}`}
                        className="relative inline-block text-lg md:text-base text-gray-800 font-medium
                                   after:content-[''] after:absolute after:w-0 after:h-0.5 
                                   after:bg-green-700 after:left-1/2 after:-bottom-1
                                   after:transition-all after:duration-300 
                                   hover:after:w-full hover:after:left-0
                                   hover:text-green-700"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavClick(item);
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="md:hidden mt-auto mb-10 flex justify-center">
                  <button
                    className="px-3 py-2 text-sm text-gray-800 hover:text-green-700 transition-colors"
                    onClick={() => changeLanguage("ko")}
                  >
                    {t("ko")}
                  </button>
                  <span className="text-gray-400 text-sm mt-[8px]">|</span>
                  <button
                    className="px-3 py-2 text-sm text-gray-800 hover:text-green-700 transition-colors"
                    onClick={() => changeLanguage("en")}
                  >
                    {t("en")}
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 z-[150] md:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
}

export default AkamaiNav;
