import React from "react";
import { useTranslation } from "react-i18next";

function Certificate() {
  const { t } = useTranslation();
  return (
    <div className="w-full py-16 px-6 sm:px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-light text-[#27ae60] mb-6">
            Fixed date Certificate
          </h2>
          <p className="text-sm md:text-lg text-gray-600 max-w-4xl mx-auto">
            {t("Certificate_SubTitle")}
          </p>
        </div>

        <div className="space-y-24">
          <FeatureSection
            title={t("FeatureSection_1nd_Title")}
            description={t("FeatureSection_1nd_desc")}
            imageSrc="/images/certificate_image1.png"
            imageAlt="간편한 확정일자"
            imageLeft={true}
          />

          <FeatureSection
            title={t("FeatureSection_2nd_Title")}
            description={t("FeatureSection_2nd_desc")}
            imageSrc="/images/certificate_image2.png"
            imageAlt="분쟁 예방"
            imageLeft={false}
          />

          <FeatureSection
            title={t("FeatureSection_3nd_Title")}
            description={t("FeatureSection_3nd_desc")}
            imageSrc="/images/certificate_image3.png"
            imageAlt="블록체인 신뢰"
            imageLeft={true}
          />
        </div>
      </div>
    </div>
  );
}

function FeatureSection({ title, description, imageSrc, imageAlt, imageLeft }) {
  return (
    <div
      className={`flex flex-col ${
        imageLeft ? "md:flex-row" : "md:flex-row-reverse"
      } items-center gap-12`}
    >
      <div className="w-full md:w-1/2">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full h-auto object-contain max-h-64 md:max-h-full"
        />
      </div>
      <div className="w-full md:w-1/2">
        <h3 className="text-xl md:text-2xl font-normal text-[#27ae60] mb-4">
          {title}
        </h3>
        <p className="text-sm md:text-lg text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export default Certificate;
