import React from "react";
import AkamaiNav from "../components/AkamaiNav";
import AkamaiBanner from "../components/AkamaiBanner";
import ClaudeSelect from "../components/ClaudeSelect";
import AkamaiMerit from "../components/AkamaiMerit";
import AkamaiPlan from "../components/AkamaiPlan";

function Akamai() {
  return (
    <>
      <AkamaiNav />
      <AkamaiBanner />
      <ClaudeSelect />
      <AkamaiMerit />
      <AkamaiPlan />
    </>
  );
}

export default Akamai;
