import React from "react";
import BlockChainBanner from "../components/BlockChainBanner";
import BlockChainNav from "../components/BlockChainNav";
import BlockChainFilter from "../components/BlockChainFilter";

function BlockChain() {
  return (
    <>
      <BlockChainNav />
      <BlockChainBanner />
      <BlockChainFilter />
    </>
  );
}

export default BlockChain;
