import React, { useState, useEffect } from "react";
import ServiceContents from "../components/ServiceContents";
import SolutionContents from "../components/SolutionContents";
import SkillContents from "../components/SkillContents";
import { useTranslation } from "react-i18next";

function Service() {
  const [activeTab, setActiveTab] = useState("services");
  const [fade, setFade] = useState(false);
  const [currentContent, setCurrentContent] = useState(<ServiceContents />);

  const { t } = useTranslation();

  useEffect(() => {
    setFade(true);
    const timeoutId = setTimeout(() => {
      setCurrentContent(renderContent());
      setFade(false);
    }, 300); // Duration should match the transition duration

    return () => clearTimeout(timeoutId);
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "services":
        return <ServiceContents />;
      case "solutions":
        return <SolutionContents />;
      case "skills":
        return <SkillContents />;
      default:
        return null;
    }
  };

  const tabs = ["services", "solutions", "skills"];

  return (
    <section
      id="services"
      className="w-full px-4 pt-24 pb-10 md:pt-40 md:pb-20"
    >
      <div className="max-w-[1280px] mx-auto w-[96%]">
        <div className="flex gap-1">
          <h3 className="text-lg md:text-2xl font-semibold ">
            {t("ourService")},
          </h3>
          <p className="text-lg md:text-2xl font-semibold mb-4 md:mb-8 text-[#386937]">
            {t("achieved")}
          </p>
        </div>
        <div>
          <ul className="text-[#000000] flex gap-3 text-md md:text-lg md:gap-4">
            {tabs.map((tab) => (
              <li
                key={tab}
                className={`px-4 py-3 text-sm md:text-base rounded-xl md:px-7 cursor-pointer transition-all duration-300 ${
                  activeTab === tab
                    ? "bg-[#386937] text-white"
                    : "bg-[#EBF0EB] hover:text-white hover:bg-[#386937]"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {t(tab)}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-5">
          <div
            className={`transition-opacity duration-150 ease-in-out ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            {currentContent}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
