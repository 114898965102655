import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslations from "../locales/en";
import koTranslations from "../locales/ko";

function initTranslations() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: enTranslations },
        ko: { translation: koTranslations },
      },
      fallbackLng: "en",
      debug: true,
      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
}

export default initTranslations;
