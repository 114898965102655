import React from "react";
import Main from "./pages/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./hook/ScrollTop";
import Footer from "./components/Footer";
import InitTranslations from "./hook/InitTranslations";
import Akamai from "./pages/Akamai";
import BlockChain from "./pages/BlockChain";
import ScrollToTop from "./hook/useNavigation";
import TestPage from "./pages/TestPage";

function App() {
  InitTranslations();
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/akamai" element={<Akamai />} />
          <Route path="/blockChain" element={<BlockChain />} />
          <Route path="/TestPage" element={<TestPage />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
